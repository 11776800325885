import {
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  Input,
  OnInit,
} from '@angular/core';
import { Section } from '../../model/section.model';
import {
  VisibilityControl,
  VisibilityController,
} from '../../service/visibility.controller';

@Component({
  selector: 'hpm-section',
  templateUrl: './section.component.html',
  styleUrl: './section.component.scss',
  changeDetection: ChangeDetectionStrategy.Default, // must be Default. OnPush doesn't update visibility properly
})
export class SectionComponent implements OnInit, DoCheck {
  @Input() section: Section | undefined;
  @Input() areaName!: string;
  @Input() hygieneInspectionDisabled = false;
  @Input() visibilityController: VisibilityController =
    new VisibilityController();
  visibilityControl!: VisibilityControl;
  @Input() showQuestionIdTooltips!: boolean;

  ngOnInit(): void {
    if (this.section) {
      this.visibilityControl =
        this.visibilityController.buildVisibilityControlForCondition(
          this.section.condition,
        );
    }
  }

  ngDoCheck(): void {
    if (this.visibilityControl && this.section) {
      if (!this.visibilityControl.isShown()) {
        // If the precondition is no longer met, set all questions in the section to false
        this.section.questions.forEach((q) => {
          q.value = false;
        });
      }
    }
  }
}
