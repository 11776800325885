import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Document } from '../../../../../shared/model/document.model';
import { Subject, takeUntil } from 'rxjs';
import { DocumentHttpService } from '../../../../../shared/service/document-http.service';
import { Order } from '../../../../order.model';
import {
  AuthorizedUser,
  hasAnyOfThoseRoles,
  UserRole,
} from '../../../../../shared/model/user.model';
import { UserHttpService } from '../../../../../shared/service/user-http.service';

@Component({
  selector: 'hpm-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrl: './upload-documents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UploadDocumentsComponent implements OnInit, OnDestroy {
  @Input() order!: Order;
  @Input() documentsDisabled = false;
  private onDestroy$ = new Subject<void>();
  documentList: Document[] = [];
  currentUser: AuthorizedUser | null = null;
  uploaderAcceptedFiletypes =
    'image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  constructor(
    private documentHttpService: DocumentHttpService,
    private userService: UserHttpService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadDocumentsByOrderId();
    this.getCurrentUser();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  loadDocumentsByOrderId(): void {
    if (this.order.id) {
      this.loadDocumentList(this.order.id);
    }
  }

  private loadDocumentList(orderId: string): void {
    this.documentHttpService
      .getDocumentsForOrder(orderId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((documentList) => {
        this.documentList = documentList;
        this.cdr.detectChanges();
      });
  }

  getUploaderEndpoint(): string {
    return this.order.id
      ? this.documentHttpService.getUploadOtherDocumentEndpoint(this.order.id)
      : '';
  }

  bytesToHuman(size: number): string {
    if (size === 0) {
      return '0 Bytes';
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const pow = Math.floor(Math.log(size) / Math.log(1024));
    const value = size / Math.pow(1000, pow);

    return value.toFixed(2) + ' ' + sizes[pow];
  }

  downloadDocument(documentId: string): string {
    if (this.order.id) {
      return this.documentHttpService.getDocumentByIdEndpoint(
        this.order.id,
        documentId,
      );
    } else {
      return '';
    }
  }

  getCurrentUser(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentUser) => {
        this.currentUser = currentUser;
      });
    this.cdr.detectChanges();
  }

  allowedToDeleteDocument(): boolean {
    if (
      hasAnyOfThoseRoles(this.currentUser, [
        UserRole.RETAIL_PARTNER,
        UserRole.FIELD_SERVICE,
      ]) &&
      this.order.status !== 'IN_CAPTURE'
    ) {
      return false;
    } else {
      return true;
    }
  }

  deleteDocument(documentId: string): void {
    if (this.order.id) {
      this.documentHttpService
        .deleteDocument(this.order.id, documentId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.loadDocumentsByOrderId();
        });
    }
  }
}
