import { LocalizedString } from '../../../../../../shared/model/localizedString';
import { Question } from './question.model';
import { Condition } from './condition.model';

export interface Section {
  id: string;
  title?: LocalizedString;
  condition?: Condition;
  notes?: Notes;
  questions: Question[];
}

export interface Notes {
  comment: string;
  qm: string;
}

export function getEmptyNotes(): Notes {
  return {
    comment: '',
    qm: '',
  };
}
