<h5>{{ 'ORDER_DETAIL.DOCUMENTS.TABLE.TABLE_TITLE'| translate }}</h5>

<cx-table-container [tableLoading]="tableLoading">
  <cx-table-with-pagination
    [initialTableColumnsOrder]="columns"
    [data]="requestedDocuments"
    [stickyHeader]="false"
    [showTableBorder]="true"
    [showTableRowHover]="false"
    [minLengthForPaginator]="10"
    [sorting]="tableSettings.sort"
    (sortChange)="onSortingDirectionChanged($event)"
    [stickyHeaderTopValue]="'125px'"
    [paginatorPageSize]="tableSettings.pageSize"
    [paginatorPageIndex]="tableSettings.pageNumber"
    [paginatorTotalRecordsCount]="totalElementCount"
    (paginatorChange)="onPaginatorChange($event)"
    matSort
  >
    <!-- Document Column -->
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>{{ "ORDER_DETAIL.DOCUMENTS.TABLE.DOCUMENT_TITLE" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>{{ row.title }}</div>
      </mat-cell>
    </ng-container>

    <!-- Version Column -->
    <ng-container matColumnDef="version">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ "ORDER_DETAIL.DOCUMENTS.TABLE.VERSION" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="center">{{ row.version }}</div>
      </mat-cell>
    </ng-container>

    <!-- Quantity Column -->
    <ng-container matColumnDef="quantity">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>
        <div class="quantity-header">
          {{ "ORDER_DETAIL.DOCUMENTS.TABLE.DOCUMENT_QUANTITY" | translate }}
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i= index">
        <div class="quantity-wrapper">
          <hpm-number-input [initialValue]="getRequestedDocumentQuantity(i)"
                            [documentsDisabled]="documentsDisabled"
                            (valueChanged)="setQuantityForDocument(i, $event)"/>
        </div>
      </mat-cell>

    </ng-container>

    <!-- Last Update Column -->
    <ng-container matColumnDef="lastUpdate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ "ORDER_DETAIL.DOCUMENTS.TABLE.LAST_UPDATE" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.lastUpdate | date: 'dd.MM.yyyy' }}</mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ORDER_DETAIL.DOCUMENTS.TABLE.STATUS' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div [ngClass]="row.status" class="status-column-wrapper">
          {{ "ORDER_DETAIL.DOCUMENTS.TABLE.ORDER_STATUS." + row.status | translate }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Menu Column -->
    <ng-container matColumnDef="menu">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row" cxTableCellAlignment="right">
        <cx-menu [disabled]="documentsDisabled">
          <cx-menu-item (click)="generateDocument(row.id)"
                        [ngClass]="{'disabled-menu-item': !allowedToGenerateOrEdit()}">
            <mat-icon class="material-symbols-outlined">library_add</mat-icon>
            {{ 'ORDER_DETAIL.DOCUMENTS.TABLE.GENERATE' | translate }}
          </cx-menu-item>
          <cx-menu-item *ngIf="row.status !== 'REQUESTED'"
                        [ngClass]="{'disabled-menu-item': !allowedToDownload(row)}"
                        hpmSecureDownload
                        [fileName]="row.title"
                        [url]="getDocxUrl(row.id)"
          >
            <mat-icon class="material-symbols-outlined">download</mat-icon>
            {{ 'ORDER_DETAIL.DOCUMENTS.TABLE.DOWNLOAD' | translate }}
          </cx-menu-item>
          <cx-menu-item [hpmConfirmDelete]="'UTILS.DISCARD_DIALOG.TITLE' | translate:{documentTitle: row.title}"
                        (confirmation)="discardDocument(row.id, $event)"
                        [ngClass]="{'disabled-menu-item': !allowedToDiscard()}">
            <mat-icon class="material-symbols-outlined">delete</mat-icon>
            {{ 'ORDER_DETAIL.DOCUMENTS.TABLE.DISCARD' | translate }}


          </cx-menu-item>
        </cx-menu>

      </mat-cell>
    </ng-container>

    <div>
      <ng-container
        cxTableLabel="{{'ORDER_DETAIL.DOCUMENTS.TABLE.TABLE_LABEL_ROWS_PER_PAGE' | translate}}"
        [type]="tableLabelType.PAGINATOR_ITEMS_PER_PAGE_LABEL"
      />
      <ng-container
        cxTableLabel="{start} - {end } / { totalRecords } {{'ORDER_DETAIL.DOCUMENTS.TABLE.TABLE_LABEL_ROW' | translate}}"
        [type]="tableLabelType.PAGINATOR_ITEMS_OUT_OF_ALL_LABEL"/>
    </div>

  </cx-table-with-pagination>
  <cx-table-loading-indicator loadingIndicatorText="Loading..."></cx-table-loading-indicator>
</cx-table-container>


<div class="button-container">
  <button mat-flat-button color="accent" (click)="generateAllDocument()"
          [disabled]="!(!documentsDisabled && isPossibleToGenerate() && allowedToGenerateOrEdit())">{{ "ORDER_DETAIL.DOCUMENTS.TABLE.GENERATE_BUTTON" | translate }}
  </button>
</div>
<div class="button-container">

  <button mat-flat-button color="accent"
          hpmSecureDownload
          [url]="getAllDocxUrl()"
          [fileName]="'document.zip'"
          [disabled]="!canBeDownloaded()">{{ "ORDER_DETAIL.DOCUMENTS.TABLE.DOWNLOAD_BUTTON" | translate }}
  </button>

</div>
