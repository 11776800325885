import { Injectable } from '@angular/core';
import { CxHeartBeatService } from '@bbraun/cortex/carousel';
import { MatDialogRef } from '@angular/material/dialog';
import { CxDialogHeartBeatComponent } from '@bbraun/cortex/heart-beat/dialog/heart-beat-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {
  private dialogReferences: MatDialogRef<CxDialogHeartBeatComponent>[] = [];

  constructor(private heartbeatService: CxHeartBeatService) {}

  loading(isLoading: boolean, message = ''): void {
    if (isLoading) {
      this.dialogReferences.push(
        this.heartbeatService.openHeartBeatDialog(message),
      );
    } else if (this.dialogReferences.length > 0) {
      this.dialogReferences.pop()?.close();
    }
  }

  closeAll(): void {
    this.dialogReferences.forEach((dialog) => {
      dialog.close();
    });
    this.dialogReferences = [];
  }
}
