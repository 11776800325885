import { UserRole } from '../../../../../shared/model/user.model';
import { OrderStateEnum } from '../../../../../shared/model/order-state.enum';

export const ALL_STATES: OrderStateEnum[] = [
  OrderStateEnum.IN_CAPTURE,
  OrderStateEnum.IN_INCOMING_INSPECTION,
  OrderStateEnum.REQUEST_IN_INCOMING_INSPECTION,
  OrderStateEnum.IN_PROCESSING,
  OrderStateEnum.REQUEST_IN_PROCESSING,
  OrderStateEnum.IN_RELEASE,
  OrderStateEnum.REQUEST_IN_RELEASE,
  OrderStateEnum.RELEASED,
  OrderStateEnum.COMPLETED,
];

/*
This map defines allowed status transitions for users by current status.
To get all allowed status query ALLOWED_ORDER_STATE_CHANGES.get(currentUser).get(currentState)
 */

export const ALLOWED_ORDER_STATE_CHANGES: Map<
  UserRole,
  Map<OrderStateEnum, OrderStateEnum[]>
> = new Map<UserRole, Map<OrderStateEnum, OrderStateEnum[]>>([
  // ADMIN and OFFICE_SERVICE are always allowed to change to ALL_STATES
  [
    UserRole.FIELD_SERVICE,
    new Map<OrderStateEnum, OrderStateEnum[]>([
      [OrderStateEnum.IN_CAPTURE, [OrderStateEnum.IN_INCOMING_INSPECTION]],
      [
        OrderStateEnum.REQUEST_IN_INCOMING_INSPECTION,
        [OrderStateEnum.IN_INCOMING_INSPECTION],
      ],
      [OrderStateEnum.REQUEST_IN_PROCESSING, [OrderStateEnum.IN_PROCESSING]],
      [OrderStateEnum.REQUEST_IN_RELEASE, [OrderStateEnum.IN_RELEASE]],
    ]),
  ],
  [
    UserRole.RETAIL_PARTNER,
    new Map<OrderStateEnum, OrderStateEnum[]>([
      [OrderStateEnum.IN_CAPTURE, [OrderStateEnum.IN_INCOMING_INSPECTION]],
      [
        OrderStateEnum.REQUEST_IN_INCOMING_INSPECTION,
        [OrderStateEnum.IN_INCOMING_INSPECTION],
      ],
      [OrderStateEnum.REQUEST_IN_PROCESSING, [OrderStateEnum.IN_PROCESSING]],
      [OrderStateEnum.REQUEST_IN_RELEASE, [OrderStateEnum.IN_RELEASE]],
    ]),
  ],
]);
