import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DetailCleaningProgram } from '../../../model/detail-questions.model';

@Component({
  selector: 'hpm-cleaning-program',
  templateUrl: './cleaning-program.component.html',
  styleUrl: './cleaning-program.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CleaningProgramComponent {
  @Input() detail!: DetailCleaningProgram;
  @Input() hygieneInspectionDisabled = false;
}
