import { Injectable } from '@angular/core';
import { ProductProposal } from '../model/product-proposal';
import { ProductHttpService } from './product-http.service';

@Injectable({
  providedIn: 'root',
})
export class ProductProposalService {
  private productQuestionMap: Map<string, Map<string, ProductProposal[]>> =
    new Map<string, Map<string, ProductProposal[]>>();

  constructor(private productProposalHttpService: ProductHttpService) {}

  getProductProposalsByAreaAndQustionId(
    areaName: string,
    questionId: string,
  ): ProductProposal[] | undefined {
    return this.productQuestionMap.get(areaName)?.get(questionId);
  }

  getDefaultProduct(
    areaName: string,
    questionId: string,
  ): ProductProposal | undefined {
    return this.getProductProposalsByAreaAndQustionId(
      areaName,
      questionId,
    )?.find((pp) => pp.isDefault);
  }

  public initialize(): void {
    this.productProposalHttpService
      .getProductProposalList()
      .subscribe((productList) => {
        this.productQuestionMap.clear();
        this.fillAreaQuestionMap(productList);
      });
  }

  private fillAreaQuestionMap(productProposalList: ProductProposal[]): void {
    productProposalList.forEach((productProposal) => {
      let proposalMapForArea = this.productQuestionMap.get(
        productProposal.areaName,
      );
      if (!proposalMapForArea) {
        proposalMapForArea = new Map<string, ProductProposal[]>();
        this.productQuestionMap.set(
          productProposal.areaName,
          proposalMapForArea,
        );
      }

      let proposalListForQuestionId = proposalMapForArea?.get(
        productProposal.questionId,
      );
      if (!proposalListForQuestionId) {
        proposalListForQuestionId = [];
        proposalMapForArea.set(
          productProposal.questionId,
          proposalListForQuestionId,
        );
      }

      proposalListForQuestionId.push(productProposal);
    });
  }
}
