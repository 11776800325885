import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CrmRecord } from '../model/remoteAPI.model';

@Injectable({
  providedIn: 'root',
})
export class RemoteAPIHttpService {
  private readonly remoteAPIBaseURL = `${environment.baseUrl}/remoteapi`;
  private readonly crmBaseURL = `${this.remoteAPIBaseURL}/crm`;

  constructor(private httpClient: HttpClient) {}

  getCaseFromCRM(caseID: string): Observable<CrmRecord | null> {
    return this.httpClient.get<CrmRecord>(this.crmBaseURL + `/cases/${caseID}`);
  }
}
