import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Question } from '../../model/question.model';
import {
  VisibilityControl,
  VisibilityController,
} from '../../service/visibility.controller';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {
  DetailAddress,
  DetailCleaningControl,
  DetailCleaningControlSolution,
  DetailCleaningDevice,
  DetailCleaningDeviceSolution,
  DetailCleaningName,
  DetailCleaningProgram,
  DetailManufacturer,
  DetailMultipleChoice,
  DetailProductList,
  DetailProductListWithApplicationTime,
  DetailProductListWithApplicationTimeAndCleaningSolution,
  DetailProductListWithMultipleDoses,
  DetailTextField,
  DetailTextList,
} from '../../model/detail-questions.model';
import { AreaChangeDetection } from '../area-change-detection.service';
import { UserHttpService } from '../../../../../../../shared/service/user-http.service';

@Component({
  selector: 'hpm-question',
  templateUrl: './question.component.html',
  styleUrl: './question.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionComponent implements OnChanges {
  @Input() areaName!: string;
  @Input() question!: Question;
  @Input() visibilityControl!: VisibilityControl;
  @Input() visibilityController!: VisibilityController;
  @Input() hygieneInspectionDisabled = false;
  @Input() showQuestionIdTooltips!: boolean;

  constructor(
    private areaChangeDetection: AreaChangeDetection,
    private userService: UserHttpService,
  ) {}

  onToggleChange(event: MatSlideToggleChange): void {
    this.question.value = event.checked;
    this.areaChangeDetection.onChange();
  }

  getDetailAsMultipleChoice(): DetailMultipleChoice {
    return this.question.detail as DetailMultipleChoice;
  }

  getDetailAsProductList(): DetailProductList {
    return this.question.detail as DetailProductList;
  }
  getDetailAsTextList(): DetailTextList {
    return this.question.detail as DetailTextList;
  }
  getDetailAsTextField(): DetailTextField {
    return this.question.detail as DetailTextField;
  }
  getDetailAsProductListWithApplicationTime(): DetailProductListWithApplicationTime {
    return this.question.detail as DetailProductListWithApplicationTime;
  }

  getDetailAsProductListWithApplicationTimeAndCleaningSolution(): DetailProductListWithApplicationTimeAndCleaningSolution {
    return this.question
      .detail as DetailProductListWithApplicationTimeAndCleaningSolution;
  }
  getDetailAsCleaningProgram(): DetailCleaningProgram {
    return this.question.detail as DetailCleaningProgram;
  }

  getDetailAsCleaningDevice(): DetailCleaningDevice {
    return this.question.detail as DetailCleaningDevice;
  }

  getDetailAsCleaningName(): DetailCleaningName {
    return this.question.detail as DetailCleaningName;
  }

  getDetailAsCleaningDeviceSolution(): DetailCleaningDeviceSolution {
    return this.question.detail as DetailCleaningDeviceSolution;
  }
  getDetailAsCleaningControlSolution(): DetailCleaningControlSolution {
    return this.question.detail as DetailCleaningControlSolution;
  }
  getDetailAsCleaningControl(): DetailCleaningControl {
    return this.question.detail as DetailCleaningControl;
  }

  getDetailAsAddress(): DetailAddress {
    return this.question.detail as DetailAddress;
  }

  getDetailAsManufacturer(): DetailManufacturer {
    return this.question.detail as DetailManufacturer;
  }

  getDetailAsProductListWithMultipleDoses(): DetailProductListWithMultipleDoses {
    return this.question.detail as DetailProductListWithMultipleDoses;
  }

  questionDetailsVisible(): boolean {
    const questionActivated = this.question.value;
    const questionHasNoCheck = !this.question.withCheck;
    return questionActivated || questionHasNoCheck;
  }

  ngOnChanges(): void {
    if (this.visibilityControl && this.question) {
      if (!this.visibilityControl.isShown()) {
        /* If the precondition is no longer met, set this questions value to false.
         *
         * What this effectively does is hide a chain of dependent questions.
         * E.g. If B depends on A being displayed and true, and C depends on B being displayed and true.
         * Now, if A is changed to false, then B is no longer displayed. And the next line makes sure that
         * its value is also set to false. This in turn ensures that C is no longer displayed.
         *
         * Without the next line, C would still be displayed, because B would no longer be visible,
         * but might have the value true.
         *
         * Do not do for questions without a toggle, as they can never be activated again
         */
        if (this.question.withCheck) {
          this.question.value = false;
        }
      }
    }
  }
}
