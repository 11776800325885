@for (medicalPractise of medicalPractises; let i = $index; track medicalPractise) {
  <div class="medicalPractise">
  <mat-form-field>
    <mat-label>{{ "ORDER_DETAIL.GENERAL.FORM.LABEL.MEDICAL_PRACTICE" | translate }}{{ i + 1 }}</mat-label>
    @if (userHasCrmCompletionPermission()) {
      <input #medicalPractiseInput class="medicalPractiseInput" [disabled]="isDisabled" [value]="medicalPractise" matInput
             (blur)="updateValue($event, i)"
             (input)="searchCRMNumber($event)"
             [matAutocomplete]="addressComplete"
      />
    } @else {
      <input #medicalPractiseInput class="medicalPractiseInput" [disabled]="isDisabled" [value]="medicalPractise" matInput
             (blur)="updateValue($event, i)" />
    }
  </mat-form-field>
  <mat-autocomplete #addressComplete="matAutocomplete">
    @if (crmAddress) {
      <mat-option [value]="crmAddress">{{ crmAddress }}</mat-option>
    }
  </mat-autocomplete>
  <button #medicalPractiseButton (click)="removeInput(i)" mat-icon-button [disabled]="isDisabled">
    <mat-icon>close</mat-icon>
  </button>
</div>
}

<div *ngIf="isAddButtonVisible()" class="addButtonContainer">
  <mat-label
    [ngClass]="{'disabled-text': isDisabled} ">{{ "ORDER_DETAIL.GENERAL.FORM.LABEL.MEDICAL_PRACTICE" | translate }}
  </mat-label>
  <div>
    <button (click)="addInput()" class="cx-button-with-icon" type="button" [disabled]="isDisabled"
            mat-stroked-button>
      <mat-icon class="cx-icon-in-button">add</mat-icon>
      {{ "ORDER_DETAIL.GENERAL.FORM.LABEL.ADD_MEDICAL_PRACTICE" | translate }}
    </button>
  </div>
</div>
