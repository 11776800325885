import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { OrderService } from '../order.service';
import { catchError, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { OrderHistoryService } from './history/order-history.service';
import { Order } from '../../order.model';
import { LoadingIndicatorService } from '../../../shared/service/loading-indicator.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hpm-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrl: './order-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // add padding to MatHeader
  encapsulation: ViewEncapsulation.None,
})
export class OrderDetailComponent implements OnInit, OnDestroy {
  headerTitle: string | null = null;
  selectedTab = 0;
  private onDestroy$: Subject<void> = new Subject<void>();
  order: Order | undefined;
  isLoading = false;

  constructor(
    private orderService: OrderService,
    private translateService: TranslateService,
    private orderHistoryService: OrderHistoryService,
    private loadingService: LoadingIndicatorService,
    private cdr: ChangeDetectorRef,
    private route: Router,
  ) {}

  ngOnInit(): void {
    this.loadOrderByPath();
    this.setHeaderTitle();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.setLoading(false);
  }

  setHeaderTitle(): void {
    this.headerTitle = this.order?.customer || null;
  }

  saveOrder(comment?: string): void {
    if (this.order) {
      this.setHeaderTitle();
      this.setLoading(true, 'ORDER_DETAIL.SAVE');
      this.orderService
        .save(this.order, comment)
        .pipe(
          takeUntil(this.onDestroy$),
          catchError((err) => {
            this.setLoading(false);
            throw err;
          }),
        )
        .subscribe(() => {
          this.setLoading(false);
        });
    }
  }

  private loadOrderByPath(): void {
    const urlSegments = this.route.url.split('/');
    const path = urlSegments[urlSegments.length - 1];
    this.setLoading(true, 'ORDER_DETAIL.LOAD');

    this.orderService
      .loadByPath(path)
      .pipe(
        takeUntil(this.onDestroy$),
        catchError((err) => {
          this.setLoading(false);
          this.order = undefined;
          throw err;
        }),
      )
      .subscribe((order) => {
        this.order = order;
        this.setHeaderTitle();
        this.setLoading(false);
        this.cdr.detectChanges();
      });
  }

  openHistory(): void {
    if (this.order) {
      this.orderHistoryService.open(this.order);
    }
  }

  setLoading(isLoading: boolean, messageKey?: string): void {
    this.isLoading = isLoading;
    if (messageKey) {
      this.translateService.get(messageKey).subscribe((message) => {
        this.loadingService.loading(isLoading, message);
      });
    } else {
      this.loadingService.loading(isLoading);
    }
  }
}
