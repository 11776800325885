import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {DetailTextList} from '../../../model/detail-questions.model';
import { AreaChangeDetection } from '../../area-change-detection.service';

@Component({
  selector: 'hpm-text-list',
  templateUrl: '../text-list/text-list.component.html',
  styleUrl: './text-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TextListComponent implements OnInit{
  @Input() detail!: DetailTextList;
  @Input() area!: string;
  @Input() hygieneInspectionDisabled = false;


  constructor(private areaChangeDetection: AreaChangeDetection) {}

  ngOnInit(): void {
    this.ensureInputNotEmpty(true);
  }

  private ensureInputNotEmpty(initialization: boolean): void {
    if (!this.detail.values){
      this.detail.values=[];
    }
    if (this.detail.values.length === 0) {
      this.detail.values.push("");
    }

  }
  addLine(index: number): void {
    this.detail.values.splice(index + 1, 0, "");
    this.areaChangeDetection.onChange();
  }

  removeLine(index: number): void {
    this.detail.values.splice(index, 1);
    this.ensureInputNotEmpty(false);
    this.areaChangeDetection.onChange();
  }
}
