import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HistoryComponent } from './history.component';
import { filter } from 'rxjs';
import { Order } from '../../../order.model';

@Injectable({ providedIn: 'root' })
export class OrderHistoryService {
  private overlayRef: OverlayRef | undefined;

  constructor(private overlay: Overlay) {}

  open(order: Order): void {
    if (this.overlayRef) {
      return;
    }

    const overlayConfig = this.overlay.position().global().end();
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: overlayConfig,
    });
    const injector: Injector = Injector.create({
      providers: [{ provide: 'Order', useValue: order }],
    });

    const overlayComponentPortal = new ComponentPortal(
      HistoryComponent,
      null,
      injector,
    );
    this.overlayRef.attach(overlayComponentPortal);
    this.overlayRef
      .keydownEvents()
      .pipe(filter((event: KeyboardEvent) => event.code === 'Escape'))
      .subscribe(() => this.closeDialog());

    this.overlayRef.backdropClick().subscribe(() => this.closeDialog());
  }

  closeDialog(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }
}
