export interface CrmRecord {
  id?: string;
  caseNumber?: string;
  subject?: string;
  caseReason?: string;
  recordTypeName?: string;
  status?: string;
  resolution?: string;
  createdDate?: string;
  closedDate?: string;
  description?: string;
  account?: CrmAccount;
  contact?: CrmContact;
}

export function getAddress(record: CrmRecord): string {
  const street = record?.account?.street;
  const zip = record?.account?.zip;
  const city = record?.account?.city;

  return (
    (street ? `${street}, ` : '') +
    (zip ? `${zip} ` : '') +
    (city ? `${city}` : '')
  );
}

export interface CrmAccount {
  sapId?: string;
  name?: string;
  zip?: string;
  city?: string;
  street?: string;
  state?: string;
  country?: string;
  medicalSpecialities: string;
}

export interface CrmContact {
  academicTitle?: string;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  phone?: string;
}
