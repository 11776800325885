import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Document } from '../model/document.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentHttpService {
  private getUserBaseUrl(orderId: string): string {
    return `${environment.baseUrl}/order/${orderId}/document`;
  }

  constructor(private httpClient: HttpClient) {}

  getDocumentByIdEndpoint(orderId: string, documentId: string): string {
    return `${this.getUserBaseUrl(orderId)}/${documentId}`;
  }

  getDocumentsForOrder(orderId: string): Observable<Document[]> {
    return this.httpClient.get<Document[]>(
      `${this.getUserBaseUrl(orderId)}/view`,
    );
  }

  getPreliminaryDocumentsForOrder(orderId: string): Observable<Document[]> {
    return this.httpClient.get<Document[]>(
      `${this.getUserBaseUrl(orderId)}/view/preliminary`,
    );
  }

  getProtocolDocumentsForOrder(orderId: string): Observable<Document[]> {
    return this.httpClient.get<Document[]>(
      `${this.getUserBaseUrl(orderId)}/view/protocol`,
    );
  }

  getUploadProtocolDocumentEndpoint(orderId: string): string {
    return `${this.getUserBaseUrl(orderId)}/protocol`;
  }

  getUploadPreliminaryDocumentEndpoint(orderId: string): string {
    return `${this.getUserBaseUrl(orderId)}/preliminary`;
  }

  getUploadOtherDocumentEndpoint(orderId: string): string {
    return `${this.getUserBaseUrl(orderId)}/other`;
  }

  deleteDocument(orderId: string, documentId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.getUserBaseUrl(orderId)}/${documentId}`,
    );
  }
}
