@for (product of detail.products; track product) {
  <div class="cleaning-control-solution">
    <hpm-product-field [area]="area"
                       [questionId]="questionId"
                       [hygieneInspectionDisabled]="hygieneInspectionDisabled"
                       [product]="product"
                       (selectedProductProposal)="onSelectProductProposal($index, $event)">
    </hpm-product-field>

    <mat-form-field class="input-field">
      <mat-select hpmAreaChangeListener
                  [(ngModel)]="product.time"
                  [disabled]="hygieneInspectionDisabled"
                  (selectionChange)="changedProductTime($index)">
        <mat-option
          value="{{controlSolutionEfficiency.TUBERKULOZID}}">{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.CLEANING_CONTROL_SOLUTION.TUBERKULOZID' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <hpm-contact-time-field class="input-field"
                            [hygieneInspectionDisabled]="hygieneInspectionDisabled"
                            [(applicationTime)]="product.applicationTime"
                            [productProposal]="selectedProductProposal.get($index)"
    >
    </hpm-contact-time-field>

    <div class="addAndRemoveButtons">
      <button (click)="removeLine($index)" mat-icon-button [disabled]="hygieneInspectionDisabled">
        <mat-icon>remove</mat-icon>
      </button>
      <button (click)="addLine($index)" mat-icon-button [disabled]="hygieneInspectionDisabled">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  @if (showCleaningControlSolutionRow()) {
    <hpm-cleaning-solution-row class="cleaning-solution-input"
                               [hygieneInspectionDisabled]="hygieneInspectionDisabled"
                               [label]="detail.label | localeString"
                               [introduction]="detail.introduction | localeString"
                               [product]="product"
    />
  }
}


