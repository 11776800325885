<div class="col-12">
  <h5>{{ "ORDER_DETAIL.HYGIENE_INSPECTION.PRELIMINARY_CHECKLIST.HEADLINE_UPLOADER" | translate }}</h5>

  <hpm-file-uploader
    [buttonText]=" 'ORDER_DETAIL.HYGIENE_INSPECTION.PRELIMINARY_CHECKLIST.UPLOAD.BUTTON' | translate"
    [description]=" 'ORDER_DETAIL.HYGIENE_INSPECTION.PRELIMINARY_CHECKLIST.UPLOAD.DESCRIPTION' | translate"
    [errorMessage]=" 'ORDER_DETAIL.HYGIENE_INSPECTION.PRELIMINARY_CHECKLIST.UPLOAD.ERROR_MESSAGE' | translate"
    [heading]=" 'ORDER_DETAIL.HYGIENE_INSPECTION.PRELIMINARY_CHECKLIST.UPLOAD.HEADING' | translate"
    [endpoint]="getUploaderEndpoint()"
    [acceptedFileTypes]="uploaderAcceptedFiletypes"
    (uploadComplete)="loadDocumentList()"
    [uploaderDisabled]="hygieneInspectionDisabled"

  />

  <h5>{{ "ORDER_DETAIL.HYGIENE_INSPECTION.PRELIMINARY_CHECKLIST.HEADLINE_FILELIST" | translate }}</h5>

  <ng-container *ngFor="let document of documentList">
    <ul>
    <li class="cx-list-item d-flex border-radius">
      <mat-icon>receipt</mat-icon>
      <div class="document">
        <div>
          <a hpmSecureDownload [url]="downloadDocument(document.id)"
             [fileName]="document.filename">{{ document.filename }}</a>
        </div>
        <div class="fileSize">{{ bytesToHuman(document.size) }}</div>
      </div>
      <div class="spacer"></div>
      <button mat-icon-button (click)="deleteDocument(document.id)" *ngIf="allowedToDeleteDocument()" [disabled]="hygieneInspectionDisabled">
        <mat-icon>close</mat-icon>
      </button>
    </li>
    </ul>
    <div class="ruler"></div>
  </ng-container>
</div>
