<mat-form-field class="contact-time">
  @if (customLabel) {
    <mat-label>{{ customLabel | translate }}</mat-label>
  } @else {
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.CONTACT_TIME' | translate }}</mat-label>
  }
  <input matInput type="text" #inputElement
         [(ngModel)]="applicationTime"
         (ngModelChange)="filterAutocompleteOptions()"
         [matAutocomplete]="autocomplete"
         [disabled]="hygieneInspectionDisabled"
         (blur)="applicationTimeChange.emit(applicationTime)"
         hpmAreaChangeListener/>
</mat-form-field>

<mat-autocomplete #autocomplete>
  <mat-option *ngFor="let dose of filteredDoses"   [disabled]="hygieneInspectionDisabled"
              [value]="singleline(dose.dose)">

    <div [innerHTML]="multiline(dose.dose)"></div>
  </mat-option>
</mat-autocomplete>
