import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { OrderHistoryItem } from './order-history.model';

@Injectable({
  providedIn: 'root',
})
export class OrderHistoryHttpService {
  private getOrderBaseUrl(orderId: string): string {
    return `${environment.baseUrl}/order/${orderId}/history`;
  }

  constructor(private httpClient: HttpClient) {}

  getFullHistoryForOrder(orderId: string): Observable<OrderHistoryItem[]> {
    return this.httpClient.get<OrderHistoryItem[]>(
      `${this.getOrderBaseUrl(orderId)}/full`,
    );
  }

  getStatusHistoryForOrder(orderId: string): Observable<OrderHistoryItem[]> {
    return this.httpClient.get<OrderHistoryItem[]>(
      `${this.getOrderBaseUrl(orderId)}/status`,
    );
  }
}
