@for (product of detail.products; track product) {
  <div class="product-input">

    <hpm-product-field [product]="product"
                       [area]="area"
                       [questionId]="questionId"
                       [hygieneInspectionDisabled]="hygieneInspectionDisabled"
                       (selectedProductProposal)="onProductProposalSelected($index, $event)"
    ></hpm-product-field>
    <hpm-contact-time-field [(applicationTime)]="product.applicationTime"
                            [productProposal]="productProposals[$index]"
                            [hygieneInspectionDisabled]="hygieneInspectionDisabled"
    ></hpm-contact-time-field>

    <button class="button-remove" (click)="removeLine($index)" mat-icon-button [disabled]="hygieneInspectionDisabled">
      <mat-icon>remove</mat-icon>
    </button>
    <button (click)="addLine($index)" mat-icon-button [disabled]="hygieneInspectionDisabled">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  @if (showCleaningSolutionRow()) {
    <hpm-cleaning-solution-row class="cleaning-solution-input"
                               [label]="detail.label | localeString"
                               [introduction]="detail.introduction | localeString"
                               [product]="product"
                               [hygieneInspectionDisabled]="hygieneInspectionDisabled"
    />
  }
}
