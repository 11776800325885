import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DocumentHttpService } from '../../../../../shared/service/document-http.service';
import { Subject, takeUntil } from 'rxjs';
import { Document } from '../../../../../shared/model/document.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Order } from '../../../../order.model';
import {
  AuthorizedUser,
  hasAnyOfThoseRoles,
  UserRole,
} from '../../../../../shared/model/user.model';
import { UserHttpService } from '../../../../../shared/service/user-http.service';

@Component({
  selector: 'hpm-protocol',
  templateUrl: './protocol.component.html',
  styleUrl: './protocol.component.scss',
  // no encapsulation to apply uploader-style to preserve description line-breaks
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtocolComponent implements OnInit, OnDestroy {
  @Input() order!: Order;
  currentUser: AuthorizedUser | null = null;
  @Output() orderChange: EventEmitter<void> = new EventEmitter<void>();
  @Input() hygieneInspectionDisabled = false;
  private onDestroy$ = new Subject<void>();
  uploaderAcceptedFiletypes =
    'image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  protocolForm: FormGroup;
  documentList: Document[] = [];

  constructor(
    private documentHttpService: DocumentHttpService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private userService: UserHttpService,
  ) {
    this.protocolForm = this.formBuilder.group({
      inspectionDate: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.patchStoredDataToForm();
    this.saveOnValueChanges();
    this.loadDocumentList();
    this.getCurrentUser();
    this.toggleDisableForm();
  }

  toggleDisableForm(): void {
    if (this.hygieneInspectionDisabled) {
      this.protocolForm.get('inspectionDate')?.disable({ emitEvent: false });
    } else {
      this.protocolForm.get('inspectionDate')?.enable({ emitEvent: false });
    }
  }

  private patchStoredDataToForm(): void {
    if (this.order.id) {
      this.protocolForm
        .get('inspectionDate')!
        .setValue(this.order.inspectionDate, {
          emitEvent: false,
        });
    }
  }

  getCurrentUser(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentUser) => {
        this.currentUser = currentUser;
      });
    this.cdr.detectChanges();
  }

  loadDocumentList(): void {
    this.documentHttpService
      .getProtocolDocumentsForOrder(this.order.id!)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((documentList) => {
        this.documentList = documentList;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getUploaderEndpoint(): string {
    return this.order.id
      ? this.documentHttpService.getUploadProtocolDocumentEndpoint(
          this.order.id,
        )
      : '';
  }

  allowedToDeleteDocument(): boolean {
    if (
      hasAnyOfThoseRoles(this.currentUser, [
        UserRole.ADMIN,
        UserRole.OFFICE_SERVICE,
      ])
    ) {
      return true;
    } else if (this.order.status === 'IN_CAPTURE') {
      return true;
    } else {
      return false;
    }
  }

  deleteDocument(documentId: string): void {
    if (this.order.id && this.allowedToDeleteDocument()) {
      this.documentHttpService
        .deleteDocument(this.order.id, documentId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.loadDocumentList();
        });
    }
  }

  bytesToHuman(size: number): string {
    if (size === 0) {
      return '0 Bytes';
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const pow = Math.floor(Math.log(size) / Math.log(1024));
    const value = size / Math.pow(1000, pow);

    return value.toFixed(2) + ' ' + sizes[pow];
  }

  downloadDocument(documentId: string): string {
    if (this.order.id) {
      return this.documentHttpService.getDocumentByIdEndpoint(
        this.order.id,
        documentId,
      );
    } else {
      return '';
    }
  }

  private saveOnValueChanges(): void {
    this.protocolForm.get('inspectionDate')?.valueChanges.subscribe((value) => {
      this.order.inspectionDate = value;
      this.orderChange.emit();
    });
  }
}
