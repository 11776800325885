@for (value of detail.values; track $index) {
  <div class="value-input">

    <div class="text-field">
    <mat-form-field>
      <mat-label>{{ detail.label | localeString  }}</mat-label>
      <input matInput
             type="text"
             [disabled]="hygieneInspectionDisabled"
             hpmAreaChangeListener
             [(ngModel)]="detail.values[$index]">

    </mat-form-field>
    </div>


      <button (click)="removeLine($index)" mat-icon-button>
        <mat-icon>remove</mat-icon>
      </button>
      <button (click)="addLine($index)" mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>
  </div>
}

