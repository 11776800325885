import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'hpm-number-input',
  templateUrl: './quantity.component.html',
  styleUrl: './quantity.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class QuantityComponent implements OnInit {
  @Input() initialValue!: number;
  @Output() valueChanged = new EventEmitter<number>();
  @Input() documentsDisabled = false;

  formControl = new FormControl(1, [
    Validators.required,
    Validators.min(0),
    Validators.max(999),
  ]);

  constructor() {}

  ngOnInit(): void {
    this.formControl.setValue(this.initialValue);
    this.toggleDisableForm();
  }

  toggleDisableForm(): void {
    if (this.documentsDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  onValueChange(value: number): void {
    let newValue = Math.floor(value);
    if (newValue < 0) {
      newValue = 0;
    }
    this.formControl.setValue(newValue);
    this.valueChanged.emit(newValue);
  }
}
