<div class="address-input">
  <mat-form-field class="address-name">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.ADDRESS.NAME' | translate }}</mat-label>
    <input matInput type="text" hpmAreaChangeListener [(ngModel)]="detail.name"  [disabled]="hygieneInspectionDisabled"/>
  </mat-form-field>
  <mat-form-field class="address-street">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.ADDRESS.STREET' | translate }}</mat-label>
    <input matInput type="text" hpmAreaChangeListener [(ngModel)]="detail.street" [disabled]="hygieneInspectionDisabled"/>
  </mat-form-field>
    <mat-form-field class="address-zipAndCity">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.ADDRESS.ZIP_AND_CITY' | translate }} </mat-label>
    <input matInput type="text" hpmAreaChangeListener [(ngModel)]="detail.zipAndCity" [disabled]="hygieneInspectionDisabled"/>
    </mat-form-field>
</div>
