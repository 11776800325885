import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { DetailProductList } from '../../../model/detail-questions.model';
import { AreaChangeDetection } from '../../area-change-detection.service';
import { ProductProposalService } from '../../../../../../../../shared/service/product-proposal.service';

@Component({
  selector: 'hpm-product-list',
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // we need to modify mat form field
  encapsulation: ViewEncapsulation.None,
})
export class ProductListComponent implements OnInit {
  @Input() detail!: DetailProductList;
  @Input() area!: string;
  @Input() questionId!: string;
  @Input() hygieneInspectionDisabled = false;

  constructor(
    private productProposalService: ProductProposalService,
    private areaChangeDetection: AreaChangeDetection,
  ) {}

  ngOnInit(): void {
    this.ensureInputNotEmpty(true);
  }

  private ensureInputNotEmpty(initialization: boolean): void {
    if (this.detail.products.length === 0) {
      const defaultProduct = this.productProposalService.getDefaultProduct(
        this.area,
        this.questionId,
      );
      if (initialization && defaultProduct) {
        this.detail.products.push({ product: defaultProduct.name });
      } else {
        this.detail.products.push({ product: '' });
      }
    }
  }

  addLine(index: number): void {
    this.detail.products.splice(index + 1, 0, { product: '' });
    this.areaChangeDetection.onChange();
  }

  removeLine(index: number): void {
    this.detail.products.splice(index, 1);
    this.ensureInputNotEmpty(false);
    this.areaChangeDetection.onChange();
  }
}
