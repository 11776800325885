import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inventory } from '../model/inventory.model';

@Injectable({
  providedIn: 'root',
})
export class InventoryHttpService {
  private getInventoryBaseUrl(orderId: string): string {
    return `${environment.baseUrl}/order/${orderId}/inventory`;
  }

  constructor(private httpClient: HttpClient) {}

  getInventoriesByOrder(orderId: string): Observable<Inventory[]> {
    return this.httpClient.get<Inventory[]>(
      `${this.getInventoryBaseUrl(orderId)}`,
    );
  }
}
