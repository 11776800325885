<div class="selection-list">
  @for (choice of detail.choices; track choice) {
    <mat-checkbox
      [matTooltip]="showQuestionIdTooltips ? choice.id : ''"
      [checked]="choice.value"
      [disabled]="hygieneInspectionDisabled"
      (change)="checkBoxChanged(choice, $event)">
      {{ choice.title | localeString }}
    </mat-checkbox>

    @if (choice.value && choice.showTextField) {
      <mat-form-field class="input" [matTooltip]="showQuestionIdTooltips ? choice.idTextField : ''">
        <input matInput hpmAreaChangeListener type="text" [disabled]="hygieneInspectionDisabled"
               [(ngModel)]="choice.textFieldValue" required/>
      </mat-form-field>
    }
    <div class="showTextList">
      @if (choice.value && choice.showTextList) {
        @for (value of choice.textFieldValues; let fieldIndex = $index; track fieldIndex) {
          <div class="textField" [matTooltip]="showQuestionIdTooltips ? choice.idTextField : ''">
            <mat-form-field class="input">
              <input matInput hpmAreaChangeListener type="text" [(ngModel)]="choice.textFieldValues![fieldIndex]"
                     [disabled]="hygieneInspectionDisabled"
                     required/>
            </mat-form-field>

            <button (click)="removeLine(fieldIndex, choice)" mat-icon-button [disabled]="hygieneInspectionDisabled">
              <mat-icon>remove</mat-icon>
            </button>
            <button (click)="addLine(fieldIndex, choice)" mat-icon-button [disabled]="hygieneInspectionDisabled">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        }
      }
    </div>
  }
</div>
