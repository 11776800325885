import { Injectable } from '@angular/core';
import { Area } from '../model/area.model';
import { QuestionnaireHttpService } from '../service/questionnaire-http.service';

@Injectable()
export class AreaService {
  area!: Area;

  constructor(private questionnaireHttpService: QuestionnaireHttpService) {}

  setArea(area: Area): void {
    this.area = area;
  }

  save(changedObjectId?: string): void {
    if (this.area) {
      this.questionnaireHttpService
        .saveArea(this.area, changedObjectId)
        .subscribe();
    }
  }
}
