import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductWithApplicationTimeAndCleaningSolution } from '../../../order/order-detail/form/hygiene-inspection/questionnaire/model/detail-questions.model';

@Component({
  selector: 'hpm-cleaning-solution-row',
  templateUrl: './cleaning-solution-row.component.html',
  styleUrl: './cleaning-solution-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CleaningSolutionRowComponent {
  @Input() label!: string;
  @Input() introduction!: string;
  @Input() product!: ProductWithApplicationTimeAndCleaningSolution;
  @Input() hygieneInspectionDisabled = false;
}
