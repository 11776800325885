import { Question } from '../model/question.model';
import { Area } from '../model/area.model';
import { Condition } from '../model/condition.model';

export class VisibilityController {
  readonly area?: Area;

  constructor(area?: Area) {
    this.area = area;
  }

  public buildVisibilityControlForQuestion(
    question: Question,
  ): VisibilityControl {
    return new VisibilityControl(this.area, question.condition);
  }

  public buildVisibilityControlForCondition(
    condition?: Condition,
  ): VisibilityControl {
    return new VisibilityControl(this.area, condition);
  }
}

export class VisibilityControl {
  static readonly alwaysTrue: () => boolean = () => true;
  static readonly alwaysFalse: () => boolean = () => false;
  readonly evaluator: () => boolean = VisibilityControl.alwaysTrue;

  constructor(area?: Area, condition?: Condition) {
    if (!condition) {
      return; // keep default evaluator, which is always true
    }

    if (area && area.content) {
      const questionToLookFor = condition.question;

      for (const section of area.content.sections) {
        for (const otherQuestion of section.questions) {
          if (otherQuestion.id === questionToLookFor) {
            this.evaluator = (): boolean =>
              condition ? otherQuestion.value === condition.value : false;
            return;
          }
        }
      }
    }

    // if it has a condition, but we cannot evaluate the condition, it gets hidden
    this.evaluator = VisibilityControl.alwaysFalse;
  }

  isShown(): boolean {
    return this.evaluator();
  }
}
