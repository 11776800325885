import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {OrderDetailComponent} from "./form/order-detail.component";

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'new'},
  {path: ':id', component: OrderDetailComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrderRoutingModule {
}
