import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { DocumentHttpService } from '../../../../../shared/service/document-http.service';
import { Subject, takeUntil } from 'rxjs';
import { Document } from '../../../../../shared/model/document.model';
import { Order } from '../../../../order.model';
import {
  AuthorizedUser,
  hasAnyOfThoseRoles,
  UserRole,
} from '../../../../../shared/model/user.model';
import { UserHttpService } from '../../../../../shared/service/user-http.service';

@Component({
  selector: 'hpm-preliminary-checklist',
  templateUrl: './preliminary-checklist.component.html',
  styleUrl: './preliminary-checklist.component.scss',
  // no encapsulation to apply uploader-style to preserve description line-breaks
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreliminaryChecklistComponent implements OnInit, OnDestroy {
  @Input() order!: Order;
  @Input() hygieneInspectionDisabled = false;
  private onDestroy$ = new Subject<void>();
  uploaderAcceptedFiletypes =
    'image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  documentList: Document[] = [];
  currentUser: AuthorizedUser | null = null;

  constructor(
    private documentHttpService: DocumentHttpService,
    private userService: UserHttpService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadDocumentList();
    this.getCurrentUser();
  }

  getCurrentUser(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentUser) => {
        this.currentUser = currentUser;
      });
    this.cdr.detectChanges();
  }

  loadDocumentList(): void {
    this.documentHttpService
      .getPreliminaryDocumentsForOrder(this.order.id!)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((documentList) => {
        this.documentList = documentList;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getUploaderEndpoint(): string {
    return this.order.id
      ? this.documentHttpService.getUploadPreliminaryDocumentEndpoint(
          this.order.id,
        )
      : '';
  }

  allowedToDeleteDocument(): boolean {
    if (
      hasAnyOfThoseRoles(this.currentUser, [
        UserRole.RETAIL_PARTNER,
        UserRole.FIELD_SERVICE,
      ]) &&
      this.order.status !== 'IN_CAPTURE'
    ) {
      return false;
    } else {
      return true;
    }
  }

  deleteDocument(documentId: string): void {
    if (this.order.id && this.allowedToDeleteDocument()) {
      this.documentHttpService
        .deleteDocument(this.order.id, documentId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.loadDocumentList();
        });
    }
  }

  bytesToHuman(size: number): string {
    if (size === 0) {
      return '0 Bytes';
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const pow = Math.floor(Math.log(size) / Math.log(1024));
    const value = size / Math.pow(1000, pow);

    return value.toFixed(2) + ' ' + sizes[pow];
  }

  downloadDocument(documentId: string): string {
    if (this.order.id) {
      return this.documentHttpService.getDocumentByIdEndpoint(
        this.order.id,
        documentId,
      );
    } else {
      return '';
    }
  }
}
