import { Order } from '../../../order.model';
import { User } from '../../../../shared/model/user.model';
import { OrderStateEnum } from '../../../../shared/model/order-state.enum';
import { LocalizedString } from '../../../../shared/model/localizedString';

export interface OrderHistoryItem {
  id: string;
  time: Date;
  order: Order;
  user: User;
  comment: string;
  orderState: OrderStateEnum;
  isLastState: boolean;
  field: ChangeType;
  oldValue: string;
  newValue: string;
  context?: Context;
}

export interface Context {
  area?: LocalizedString;
  section?: LocalizedString;
  documentTitle?: string;
}

export enum ChangeType {
  INTEGRATE_LOGO = 'INTEGRATE_LOGO',
  CUSTOMER = 'CUSTOMER',
  CONTACT = 'CONTACT',
  TELEPHONE = 'TELEPHONE',
  EMAIL = 'EMAIL',
  HYGIENE_REPRESENTATIVE = 'HYGIENE_REPRESENTATIVE',
  ADDRESS = 'ADDRESS',
  MEDICAL_PRACTICE = 'MEDICAL_PRACTICE',
  REASON = 'REASON',
  COMMISSIONED_BY = 'COMMISSIONED_BY',
  STATUS = 'STATUS',
  PRIORITY = 'PRIORITY',
  PRIORITY_REASON = 'PRIORITY_REASON',
  ASSIGNED_TO = 'ASSIGNED_TO',
  SPECIALTY_FIELD = 'SPECIALTY_FIELD',
  REQUIRED_HYGIENE_PLANS = 'REQUIRED_HYGIENE_PLANS',
  COMMENT = 'COMMENT',
  FILE_UPLOAD = 'FILE_UPLOAD',
  FILE_DELETE = 'FILE_DELETE',
  NOTES_COMMENT = 'NOTES_COMMENT',
  NOTES_QM = 'NOTES_QM',
  AREA = 'AREA',
  DOCUMENT_GENERATED = 'DOCUMENT_GENERATED',
  DOCUMENT_DISCARDED = 'DOCUMENT_DISCARDED'
}
