import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {DetailTextField} from '../../../model/detail-questions.model';
import { AreaChangeDetection } from '../../area-change-detection.service';

@Component({
  selector: 'hpm-text-field',
  templateUrl: './text-field.component.html',
  styleUrl: './text-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextFieldComponent{

  @Input() detail!: DetailTextField;
  @Input() area!: string;
  @Input() hygieneInspectionDisabled = false;

  constructor(private areaChangeDetection: AreaChangeDetection) {}

}
