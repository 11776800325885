import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Dose, ProductProposal } from '../../model/product-proposal';
import { AreaChangeDetection } from '../../../order/order-detail/form/hygiene-inspection/questionnaire/area/area-change-detection.service';

@Component({
  selector: 'hpm-contact-time-field',
  templateUrl: './contact-time-field.component.html',
  styleUrl: './contact-time-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactTimeFieldComponent implements OnChanges {
  @Input() applicationTime!: string;
  @Output() applicationTimeChange: EventEmitter<string> =
    new EventEmitter<string>();
  @Input() customLabel?: string;
  @Input() productProposal: ProductProposal | undefined;
  @Input() doseTypeFilter?: string | undefined;
  @Input() hygieneInspectionDisabled = false;
  @ViewChild('inputElement') inputElement!: ElementRef;

  filteredDoses: Dose[] = [];
  allDoses: Dose[] = [];

  constructor(public areaChangeDetection: AreaChangeDetection) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['productProposal'] && !changes['productProposal'].firstChange) {
      if (this.productProposal) {
        if (this.doseTypeFilter) {
          this.allDoses =
            this.productProposal?.doses.filter(
              (dose) => dose.type === this.doseTypeFilter,
            ) || [];
        } else {
          this.allDoses = this.productProposal?.doses;
        }
        this.filteredDoses = this.allDoses;
        if (!this.applicationTime?.length) {
          this.setDefaultDosis();
        }
      }
    }
  }

  private setDefaultDosis(): void {
    const defaultDose = this.allDoses.find((dose) => dose.isDefaultDose);
    if (defaultDose) {
      this.applicationTime = defaultDose.dose;
      this.areaChangeDetection.onChange();
      this.applicationTimeChange.emit(defaultDose.dose);
    }
  }

  filterAutocompleteOptions(): void {
    if (this.productProposal && this.allDoses) {
      this.filteredDoses = [...this.allDoses].filter((value) =>
        value?.dose.toLowerCase().match(this.applicationTime.toLowerCase()),
      );
    }
  }

  multiline(dose: string): string {
    return dose.replace('\r', '<br>').replace('\n', '<br>');
  }

  singleline(dose: string): string {
    return dose.replace('\r', ' \r').replace('\n', ' \n');
  }
}
