<div class="area">
  @if (area) {
    @for (section of area.content.sections; track section) {
      <hpm-section [section]="section"
                   [showQuestionIdTooltips]="showQuestionIdTooltips"
                   [areaName]="areaName"
                   [visibilityController]="visibilityController" [hygieneInspectionDisabled]="hygieneInspectionDisabled"></hpm-section>
    }
  }
</div>
