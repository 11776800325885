import { Directive, HostListener } from '@angular/core';
import { AreaChangeDetection } from './area-change-detection.service';

@Directive({
  selector: '[hpmAreaChangeListener]'
})
export class AreaChangeListenerDirective {

  constructor(private areaChangeDetection: AreaChangeDetection) {
  }

  @HostListener('blur')
  onBlur(): void {
    this.areaChangeDetection.onChange();
  }

}
