@if (visibilityControl.isShown()) {
  @if (question.separatorTop) {
    <mat-divider class="divider-top"></mat-divider>
  }
  <div class="question-card">
    @if ( question.detail.kind !== "multiple-choice"
      || (question.detail.kind === "multiple-choice" && (question.title | localeString))) {
      <div class="question">
        <h6 class='font-m' [innerHTML]="question.title | localeString"></h6>
      </div>
    }

    <div>
      <div>
        @if (question.withCheck) {
          <hpm-two-label-toggle
            [matTooltip]="showQuestionIdTooltips ? question.id : ''"
            (changeToggleButton)="onToggleChange($event)"
            [checked]="question.value"
            [hygieneInspectionDisabled]="hygieneInspectionDisabled"
          >
          </hpm-two-label-toggle>
        }
      </div>
      <div class="question-detail" [hidden]="!questionDetailsVisible()">
        @switch (question.detail.kind) {
          @case ("multiple-choice") {
            <hpm-multiple-choice [detail]="getDetailAsMultipleChoice()"
                                 [showQuestionIdTooltips]="showQuestionIdTooltips"
                                 [hygieneInspectionDisabled]="hygieneInspectionDisabled"/>
          }
          @case ("product-list") {
            <hpm-product-list [detail]="getDetailAsProductList()"
                              [area]="areaName"
                              [matTooltip]="showQuestionIdTooltips ? getDetailAsProductList().id : ''"
                              [hygieneInspectionDisabled]="hygieneInspectionDisabled"
                              [questionId]="question.id"/>
          }
          @case ("text-list") {
            <hpm-text-list
              [matTooltip]="showQuestionIdTooltips ? question.id : ''"
              [detail]="getDetailAsTextList()" [hygieneInspectionDisabled]="hygieneInspectionDisabled"
            />
          }
          @case ("text-field") {
            <hpm-text-field
              [matTooltip]="showQuestionIdTooltips ? question.id : ''"
              [detail]="getDetailAsTextField()" [hygieneInspectionDisabled]="hygieneInspectionDisabled"
            />
          }
          @case ("product-list-with-application-time") {
            <hpm-product-with-application-time
              [detail]="getDetailAsProductListWithApplicationTime()"
              [hygieneInspectionDisabled]="hygieneInspectionDisabled"
              [area]="areaName"
              [matTooltip]="showQuestionIdTooltips ? question.id : ''"
              [questionId]="question.id"/>
          }
          @case ("cleaning-program") {
            <hpm-cleaning-program
              [matTooltip]="showQuestionIdTooltips ? question.id : ''"
              [detail]="getDetailAsCleaningProgram()" [hygieneInspectionDisabled]="hygieneInspectionDisabled"
            />
          }
          @case ("cleaning-device") {
            <hpm-cleaning-device
              [showQuestionIdTooltips]="showQuestionIdTooltips"
              [detail]="getDetailAsCleaningDevice()" [hygieneInspectionDisabled]="hygieneInspectionDisabled"/>
          }
          @case ("cleaning-name") {
            <hpm-cleaning-name
              [showQuestionIdTooltips]="showQuestionIdTooltips"
              [detail]="getDetailAsCleaningName()" [hygieneInspectionDisabled]="hygieneInspectionDisabled"/>
          }
          @case ("cleaning-device-solution") {
            <hpm-cleaning-device-solution
              [matTooltip]="showQuestionIdTooltips ? question.id : ''"
              [detail]="getDetailAsCleaningDeviceSolution()"
              [visibilityController]="visibilityController"
              [area]="areaName" [hygieneInspectionDisabled]="hygieneInspectionDisabled"
              [questionId]="question.id"/>
          }
          @case ("cleaning-control-solution") {
            <hpm-cleaning-control-solution
              [matTooltip]="showQuestionIdTooltips ? question.id : ''"
              [detail]="getDetailAsCleaningControlSolution()"
              [visibilityController]="visibilityController"
              [area]="areaName" [hygieneInspectionDisabled]="hygieneInspectionDisabled"
              [questionId]="question.id"/>
          }
          @case ("cleaning-control") {
            <hpm-cleaning-control
              [showQuestionIdTooltips]="showQuestionIdTooltips"
              [detail]="getDetailAsCleaningControl()" [hygieneInspectionDisabled]="hygieneInspectionDisabled"
            />
          }
          @case ("product-list-with-application-time-and-cleaning-solution") {
            <hpm-product-with-application-time-and-cleaning-solution
              [matTooltip]="showQuestionIdTooltips ? question.id : ''"
              [detail]="getDetailAsProductListWithApplicationTimeAndCleaningSolution()"
              [visibilityController]="visibilityController"
              [area]="areaName"
              [questionId]="question.id"
              [hygieneInspectionDisabled]="hygieneInspectionDisabled"
            />
          }
          @case ("address") {
            <hpm-address [detail]="getDetailAsAddress()"
                         [matTooltip]="showQuestionIdTooltips ? question.id : ''"
                         [hygieneInspectionDisabled]="hygieneInspectionDisabled"></hpm-address>
          }
          @case ("contact") {
            <hpm-contact [detail]="getDetailAsManufacturer()"
                         [showQuestionIdTooltips]="showQuestionIdTooltips"
                         [hygieneInspectionDisabled]="hygieneInspectionDisabled"></hpm-contact>
          }
          @case ("product-list-with-multiple-doses") {
            <hpm-product-list-with-multiple-doses [questionId]="question.id"
                                                  [matTooltip]="showQuestionIdTooltips ? question.id : ''"
                                                  [area]="areaName"
                                                  [hygieneInspectionDisabled]="hygieneInspectionDisabled"
                                                  [detail]="getDetailAsProductListWithMultipleDoses()">

            </hpm-product-list-with-multiple-doses>
          }
          @case ("none") {
          }
          @default {
            <span>Unknown question type</span>
          }
        }
      </div>

    </div>

  </div>
  @if (question.separatorBottom) {
    <mat-divider class="divider-bottom"></mat-divider>
  }

}
