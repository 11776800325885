import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { Order } from '../order.model';
import { OrderHttpService } from './order-http.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private orderHttpService: OrderHttpService,
    private router: Router,
  ) {}

  save(order: Order, comment?: string): Observable<string> {
    return this.orderHttpService.save(order, comment);
  }

  private loadNew(): Observable<Order> {
    return this.orderHttpService.getNewOrder().pipe(
      switchMap((newOrderId) => {
        this.router.navigateByUrl(`/order/${newOrderId}`);
        return this.orderHttpService.getOrderForId(newOrderId);
      }),
    );
  }

  loadByPath(path: string): Observable<Order | undefined> {
    if (path === 'new') {
      return this.loadNew();
    } else {
      return this.orderHttpService.getOrderForId(path);
    }
  }
}
