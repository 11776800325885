import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DataHttpService {
  private readonly baseUrl = `${environment.baseUrl}`;

  constructor(private httpClient: HttpClient) {}

  getSpecialties(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.baseUrl}/specialties`);
  }
}
