import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { RemoteAPIHttpService } from '../../../../../shared/service/remoteAPI-http.service';
import { getAddress } from '../../../../../shared/model/remoteAPI.model';
import { debounceTime, firstValueFrom, map, Subject, takeUntil } from 'rxjs';
import {
  AuthorizedUser,
  hasAnyOfThoseRoles,
  UserRole,
} from '../../../../../shared/model/user.model';

@Component({
  selector: 'hpm-medical-practise',
  templateUrl: './medical-practise.component.html',
  styleUrl: './medical-practise.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedicalPractiseComponent),
      multi: true,
    },
  ],
})
export class MedicalPractiseComponent
  implements ControlValueAccessor, OnDestroy, OnInit
{
  readonly MAX_LIMIT = 10;
  onDestroy$ = new Subject<void>();
  valueChanged: Subject<void> = new Subject<void>();
  medicalPractises: string[] = [];
  crmAddress: string | undefined;
  @Input() isDisabled = false;
  @Input() currentUser!: AuthorizedUser | null;
  @ViewChildren('medicalPractiseInput')
  medicalPractiseInputs: QueryList<ElementRef> | undefined;
  @ViewChildren('medicalPractiseButton')
  medicalPractiseButtons: QueryList<MatButton> | undefined;

  constructor(
    private cdr: ChangeDetectorRef,
    private remoteApiService: RemoteAPIHttpService,
  ) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */ // any type required for CVA interface
  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /* eslint-enable */

  writeValue(value: string[]): void {
    if (value) {
      this.medicalPractises = value;
    } else {
      this.medicalPractises = [];
    }
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    // debounce change output because selecting autocomplete would emit several times
    this.valueChanged
      .pipe(takeUntil(this.onDestroy$), debounceTime(500))
      .subscribe(() => this.onChange(this.medicalPractises));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  userHasCrmCompletionPermission(): boolean {
    return hasAnyOfThoseRoles(this.currentUser, [
      UserRole.ADMIN,
      UserRole.OFFICE_SERVICE,
      UserRole.FIELD_SERVICE,
    ]);
  }

  async searchCRMNumber(input: Event): Promise<void> {
    // @ts-expect-error value is read from input.target.value
    const crmNumber: string = input.target['value'];
    if (crmNumber && crmNumber.length > 0 && this.isNumber(crmNumber)) {
      this.crmAddress = await firstValueFrom(
        this.remoteApiService.getCaseFromCRM(crmNumber).pipe(
          map((record) => {
            if (record) {
              return getAddress(record);
            }
            return undefined;
          }),
        ),
      );
      this.cdr.detectChanges();
    }
  }

  private isNumber(crmNumber: string): boolean {
    return !Number.isNaN(Number.parseInt(crmNumber));
  }

  updateValue(input: FocusEvent, index: number): void {
    setTimeout(() => {
      // wait, to give autocomplete time to write into field first
      // @ts-expect-error value is read from input.target.value
      const newValue = input.target?.['value'];
      if (newValue && newValue.length > 0) {
        this.medicalPractises[index] = newValue;
        this.valueChanged.next();
      } else {
        this.removeInput(index);
      }
      this.crmAddress = undefined;
      this.cdr.detectChanges();
    }, 300);
  }

  removeInput(index: number): void {
    this.medicalPractises.splice(index, 1);
    this.valueChanged.next();
  }

  addInput(): void {
    this.medicalPractises.push('');
    this.cdr.detectChanges();
    this.medicalPractiseInputs?.last.nativeElement.focus();
  }

  isAddButtonVisible(): boolean {
    return (
      !!this.medicalPractises && this.medicalPractises?.length < this.MAX_LIMIT
    );
  }
}
