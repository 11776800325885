import { Injectable } from '@angular/core';
import { AreaService } from './area.service';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AreaChangeDetection {
  private areaChanged$: Subject<string | undefined> = new Subject();

  constructor(private areaService: AreaService) {
    this.areaChanged$.subscribe((changedObjectId) =>
      this.areaService.save(changedObjectId),
    );
  }

  onChange(changedObjectId?: string): void {
    this.areaChanged$.next(changedObjectId);
  }

  areaHasChanged(): Observable<string | undefined> {
    return this.areaChanged$.asObservable();
  }
}
