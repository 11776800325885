import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Area } from '../model/area.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireHttpService {
  private readonly areaBaseUrl = `${environment.baseUrl}/questionnaire/area`;

  constructor(private httpClient: HttpClient) {}

  getAreaByName(orderId: string, areaName: string): Observable<Area> {
    return this.httpClient.get<Area>(`${this.areaBaseUrl}/${orderId}`, {
      params: { areaName },
    });
  }

  saveArea(area: Area, changedObjectId?: string): Observable<void> {
    return this.httpClient.post<void>(`${this.areaBaseUrl}`, { areaToSave: area, changedObjectId });
  }
}
