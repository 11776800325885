import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Order } from '../../../order.model';
import { Subject, takeUntil } from 'rxjs';
import { UserHttpService } from '../../../../shared/service/user-http.service';

@Component({
  selector: 'hpm-documents',
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsComponent implements OnInit, OnDestroy {
  @Input() order!: Order;
  documentsDisabled = false;
  @Output() orderChange: EventEmitter<void> = new EventEmitter<void>();
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private userService: UserHttpService) {}

  ngOnInit(): void {
    this.disableDocuments();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  disableDocuments(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentUser) => {
        const userName = currentUser?.username;
        if (userName !== this.order.assignedTo.username) {
          this.documentsDisabled = true;
        }
      });
  }
}
