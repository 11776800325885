import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ChangeType, Context, OrderHistoryItem } from './order-history.model';
import { LocaleStringPipe } from '../../../../shared/util/pipes/locale-string.pipe';

@Injectable({
  providedIn: 'root',
})
export class HistoryTranslationService {
  constructor(
    private translateService: TranslateService,
    private localizedStringPipe: LocaleStringPipe,
  ) {}

  getChangeText(history: OrderHistoryItem): Observable<string> {
    const username = `${history.user.firstname} ${history.user.lastname}`;
    switch (history.field) {
      case ChangeType.REASON:
      case ChangeType.SPECIALTY_FIELD:
      case ChangeType.REQUIRED_HYGIENE_PLANS:
        return this.translateFieldWithValue(
          username,
          history.field,
          'LIST',
          history.newValue,
        );

      case ChangeType.INTEGRATE_LOGO:
      case ChangeType.PRIORITY:
        return this.translateWithParameter(
          username,
          history.field,
          history.newValue,
          'TOGGLE',
        );

      case ChangeType.MEDICAL_PRACTICE:
        return this.translateWithParameter(
          username,
          history.field,
          history.newValue,
        );

      case ChangeType.COMMENT:
        return this.translate('ORDER_DETAIL.HISTORY.CHANGES.COMMENT', {
          username,
        });

      case ChangeType.STATUS:
        return this.translateStatus(
          username,
          history.oldValue,
          history.newValue,
        );

      case ChangeType.ASSIGNED_TO: {
        const oldUser = history.oldValue ? history.oldValue : '-';
        return this.translate('ORDER_DETAIL.HISTORY.CHANGES.USER', {
          username,
          oldUser: oldUser,
          newUser: history.newValue,
        });
      }

      case ChangeType.FILE_UPLOAD: {
        return this.translateWithParameter(
          username,
          history.newValue,
          history.newValue,
          history.field,
        );
      }

      case ChangeType.FILE_DELETE: {
        return this.translateWithParameter(
          username,
          history.oldValue,
          history.oldValue,
          history.field,
        );
      }

      case ChangeType.NOTES_COMMENT:
      case ChangeType.NOTES_QM: {
        return this.translateSectionNotes(
          username,
          history.newValue,
          history.context,
          history.field,
        );
      }

      case ChangeType.AREA: {
        const area = this.localizedStringPipe.transform(
          history.context?.area ?? { de: '' },
        );
        return this.translate('ORDER_DETAIL.HISTORY.CHANGES.AREA', {
          username,
          area,
        });
      }

      case ChangeType.DOCUMENT_GENERATED:
        return this.translate(
          'ORDER_DETAIL.HISTORY.CHANGES.DOCUMENT_GENERATED',
          {
            username,
            title: history.context?.documentTitle,
          },
        );
      case  ChangeType.DOCUMENT_DISCARDED:
        return this.translate(
          'ORDER_DETAIL.HISTORY.CHANGES.DOCUMENT_DISCARDED',
          {
            username,
            title: history.context?.documentTitle
          },
        );

      default:
        return this.translateFieldWithValue(
          username,
          history.field,
          'TEXT',
          history.newValue,
        );
    }
  }

  private translate(key: string, parameters: object): Observable<string> {
    return this.translateService.get(key, parameters);
  }

  private translateFieldWithValue(
    username: string,
    changeTypeKey: string,
    fieldTypeKey: string,
    newValue: string,
  ): Observable<string> {
    return this.translateService
      .get('ORDER_DETAIL.HISTORY.CHANGES.CHANGE_TYPE.' + changeTypeKey)
      .pipe(
        switchMap((field: string) => {
          return this.translateService.get(
            'ORDER_DETAIL.HISTORY.CHANGES.' + fieldTypeKey,
            { username, field, newValue },
          );
        }),
      );
  }

  private translateWithParameter(
    username: string,
    changeTypeKey: string,
    parameter: string,
    fieldType?: string,
  ): Observable<string> {
    if (!fieldType) {
      fieldType = changeTypeKey;
    }
    return this.translateService
      .get('ORDER_DETAIL.HISTORY.CHANGES.CHANGE_TYPE.' + changeTypeKey)
      .pipe(
        switchMap((field: string) => {
          return this.translateService
            .get('ORDER_DETAIL.HISTORY.CHANGES.' + parameter)
            .pipe(
              switchMap((newValue: string) => {
                return this.translateService.get(
                  'ORDER_DETAIL.HISTORY.CHANGES.' + fieldType,
                  { username, field, newValue },
                );
              }),
            );
        }),
      );
  }

  private translateStatus(
    username: string,
    oldStatusKey: string,
    newStatusKey: string,
  ): Observable<string> {
    return this.translateService
      .get('ORDER_OVERVIEW.ORDER_STATUS.' + oldStatusKey)
      .pipe(
        switchMap((oldStatus: string) => {
          return this.translateService
            .get('ORDER_OVERVIEW.ORDER_STATUS.' + newStatusKey)
            .pipe(
              switchMap((newStatus: string) => {
                return this.translateService.get(
                  'ORDER_DETAIL.HISTORY.CHANGES.STATUS',
                  { username, oldStatus, newStatus },
                );
              }),
            );
        }),
      );
  }

  private translateSectionNotes(
    username: string,
    changeMessage: string,
    context: Context | undefined,
    field: string,
  ): Observable<string> {
    let contextTitle: string | undefined = undefined;
    if (context) {
      if (context.area) {
        contextTitle = this.localizedStringPipe.transform(context.area);
      }
      if (context.section && contextTitle) {
        contextTitle = `${contextTitle} ${this.localizedStringPipe.transform(context.section)}`;
      } else if (context.section) {
        contextTitle = this.localizedStringPipe.transform(context.section);
      }
    }
    return this.translateService
      .get('ORDER_DETAIL.HISTORY.CHANGES.' + changeMessage)
      .pipe(
        switchMap((changeTranslations: string) => {
          return this.translateService.get(
            'ORDER_DETAIL.HISTORY.CHANGES.' + field,
            {
              username,
              section: contextTitle,
              changeMessage: changeTranslations,
            },
          );
        }),
      );
  }
}
