<div class="contact-input">
  <mat-form-field class="contact-name" [matTooltip]="showQuestionIdTooltips ? detail.idName : ''">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.CONTACT.NAME' | translate }}</mat-label>
    <input matInput type="text" hpmAreaChangeListener [(ngModel)]="detail.name" [disabled]="hygieneInspectionDisabled" />
  </mat-form-field>
  <mat-form-field class="contact-company" [matTooltip]="showQuestionIdTooltips ? detail.idCompany : ''">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.CONTACT.COMPANY' | translate }}</mat-label>
    <input matInput type="text" hpmAreaChangeListener [(ngModel)]="detail.company" [disabled]="hygieneInspectionDisabled"/>
  </mat-form-field>

</div>
