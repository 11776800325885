import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  DetailProductListWithApplicationTimeAndCleaningSolution,
  ProductWithApplicationTimeAndCleaningSolution,
} from '../../../model/detail-questions.model';
import {
  VisibilityControl,
  VisibilityController,
} from '../../../service/visibility.controller';
import { AreaChangeDetection } from '../../area-change-detection.service';
import { ProductProposal } from '../../../../../../../../shared/model/product-proposal';
import { ProductProposalService } from '../../../../../../../../shared/service/product-proposal.service';

@Component({
  selector: 'hpm-product-with-application-time-and-cleaning-solution',
  templateUrl:
    './product-with-application-time-and-cleaning-solution.component.html',
  styleUrl:
    './product-with-application-time-and-cleaning-solution.component.scss',
  changeDetection: ChangeDetectionStrategy.Default, // Needs to default to toggle visibility of second row
  // we need to modify mat form field
  encapsulation: ViewEncapsulation.None,
})
export class ProductWithApplicationTimeAndCleaningSolutionComponent
  implements OnInit
{
  @Input() detail!: DetailProductListWithApplicationTimeAndCleaningSolution;
  @Input() hygieneInspectionDisabled = false;
  @Input() visibilityController: VisibilityController =
    new VisibilityController();
  cleaningSolutionRowVisibilityControl: VisibilityControl =
    new VisibilityControl();
  @Input() area!: string;
  @Input() questionId!: string;
  productProposals: (ProductProposal | undefined)[] = [];

  constructor(
    private productProposalService: ProductProposalService,
    private areaChangeDetection: AreaChangeDetection,
  ) {}

  ngOnInit(): void {
    this.ensureInputNotEmpty(true);

    this.cleaningSolutionRowVisibilityControl =
      this.visibilityController.buildVisibilityControlForCondition(
        this.detail.conditionForCleaningSolution,
      );
  }

  showCleaningSolutionRow(): boolean {
    return this.cleaningSolutionRowVisibilityControl.isShown();
  }

  private ensureInputNotEmpty(initialization: boolean): void {
    if (this.detail.products.length === 0) {
      const defaultProduct = this.productProposalService.getDefaultProduct(
        this.area,
        this.questionId,
      );
      if (initialization && defaultProduct) {
        this.detail.products.push(this.getDefaultItem(defaultProduct));
        this.productProposals.push(defaultProduct);
      } else {
        this.detail.products.push(this.getEmptyItem());
        this.productProposals = new Array(this.detail.products.length);
      }
    }
  }

  private getEmptyItem(): ProductWithApplicationTimeAndCleaningSolution {
    return {
      product: '',
      applicationTime: '',
      volumeUnit: 'L',
      concentrateUnit: '%',
    };
  }

  private getDefaultItem(
    proposal: ProductProposal,
  ): ProductWithApplicationTimeAndCleaningSolution {
    let dose = proposal.doses?.find((d) => d.isDefaultDose)?.dose;

    if (!dose) {
      dose = '';
    }

    return {
      product: proposal.name,
      applicationTime: dose,
      volumeUnit: 'L',
      concentrateUnit: '%',
    };
  }

  addLine(index: number): void {
    this.detail.products.splice(index + 1, 0, this.getEmptyItem());
    this.productProposals.splice(index + 1, 0, undefined);
    this.areaChangeDetection.onChange();
  }

  removeLine(index: number): void {
    this.detail.products.splice(index, 1);
    this.productProposals.splice(index, 1);
    this.areaChangeDetection.onChange();
    this.ensureInputNotEmpty(false);
  }

  onProductProposalSelected(index: number, proposal: ProductProposal): void {
    this.productProposals[index] = proposal;
  }
}
