<div class="cleaning-solution-input">

  <div class="introduction">
    {{ introduction }}
  </div>

  <mat-form-field class="volumeAmount">
    <mat-label>{{ label }}</mat-label>
    <input matInput type="number" hpmAreaChangeListener [(ngModel)]="product.volumeAmount"   [disabled]="hygieneInspectionDisabled"/>
  </mat-form-field>

  <mat-form-field class="volumeUnit">
    <mat-select [(ngModel)]="product.volumeUnit" hpmAreaChangeListener   [disabled]="hygieneInspectionDisabled">
      <mat-option value="L">{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.LITRE' | translate }}</mat-option>
      <mat-option value="ml">ml</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="middle">
    {{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.CLEANING_SOLUTION.MIDDLE' | translate }}
  </div>

  <mat-form-field class="concentrateAmount">
    <input matInput type="number" hpmAreaChangeListener [(ngModel)]="product.concentrateAmount"/>
  </mat-form-field>

  <mat-form-field class="concentrateUnit">
    <mat-select [(ngModel)]="product.concentrateUnit" hpmAreaChangeListener   [disabled]="hygieneInspectionDisabled">
      <mat-option value="%">%</mat-option>
      <mat-option value="g">g</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="end">
    {{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.CLEANING_SOLUTION.END' | translate }}
  </div>
</div>
